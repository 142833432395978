import { useState, useEffect } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  BettyArrowRight,
  DRCFlag,
  GhanaFlag,
  NigeriaFlag,
  ZambiaFlag,
} from "../../assets/icons";
import {
  ModalBanner,
  ModalBanner2,
  GlobeIcon,
  AviatorIcon,
  BonusImage,
  FreeBingoImage,
} from "../../assets/images";
import {
  getLocationSelector,
  handleShowModal,
  setLocation,
} from "../../redux/reducers/getLocation";
import { Button } from "../../reusables";
import { useHistory } from "react-router-dom";
import { FaTimesCircle } from "react-icons/fa";

export const DisclaimerModal = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const exprTime = localStorage.getItem("exprTime");
  const userAge = localStorage.getItem("age");

  const setExprDate = () => {
    if (!exprTime || new Date(exprTime) <= Date.now()) {
      const currentDateObj = new Date();
      const numberOfMlSeconds = currentDateObj.getTime();
      const addMlSeconds = 438000 * 60 * 60 * 1000;
      const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
      localStorage.setItem("exprTime", newDateObj);
    } else {
      checkExprTime();
    }
  };

  const checkExprTime = () => {
    if (new Date(exprTime) <= Date.now()) {
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  const onAdult = () => {
    setShowModal(false);
    setExprDate();
    localStorage.setItem("age", "adult");
  };

  const onUnderAge = () => {
    setShowModal(false);
    setExprDate();
    localStorage.setItem("age", "minor");
    history.push("/underage");
  };

  useEffect(() => {
    checkExprTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exprTime, Date.now()]);

  useEffect(() => {
    if (userAge === "minor") history.push("/underage");
    if (exprTime && new Date(exprTime) <= Date.now()) {
      localStorage.removeItem("age");
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAge, exprTime]);

  // const { location } = useSelector(getLocationSelector);
  return (
    <>
      <Container
        centered={true}
        width={325}
        visible={showModal}
        closable={false}
        footer={false}
      >
        <img
          src="https://gamingcommission.gov.gh/images/images/logo.jpg"
          alt="Globe"
          className="globe"
          style={{ borderRadius: "50%" }}
        />
        <h3 style={{ textAlign: "center" }}>
          Betty Bingo is licensed by The Gaming Commission of Ghana -
          GCR022C1410j. The minimum age to register is 18. <br />
          Betting can be Addictive.
          <br />
          Play Responsibly.
        </h3>
        <div className="btn_group">
          <h4>Are you 18+?</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="btn-continue"
              onClick={onAdult}
              secondary
              full
              text="Yes"
              type="button"
            />
            <Button
              className="btn-cancel"
              primary
              full
              text="No"
              onClick={onUnderAge}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export const BonusModal = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const exprTime = localStorage.getItem("bonusExprTime");

  const { location } = useSelector(getLocationSelector);

  let price = "5 Million";
  let min = 200;
  let currency = "";

  if (location.code !== "") {
    currency = location.currency;
  }

  // if (location.code === "gh") {
  //   price = 145;
  //   min = 7;
  // }
  // if (location.code === "zm") {
  //   price = 200;
  //   min = 0;
  // }
  // if (location.code === "cd") {
  //   price = 22000;
  //   min = 0;
  // }

  if (location.code === "gh") {
    price = "100,000";
    min = 7;
  }
  if (location.code === "zm") {
    price = "140,000";
    min = 7;
  }
  if (location.code === "drc") {
    price = "2 Million";
    min = 800;
  }

  const setExprDate = () => {
    if (!exprTime || new Date(exprTime) <= Date.now()) {
      const currentDateObj = new Date();
      const numberOfMlSeconds = currentDateObj.getTime();
      const addMlSeconds = 86400000;
      const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
      localStorage.setItem("bonusExprTime", newDateObj);
      localStorage.setItem("bonusModalClosed", true);
    } else {
      checkExprTime();
    }
  };

  const checkExprTime = () => {
    if (new Date(exprTime) <= Date.now()) {
      setShowModal(true);
      localStorage.setItem("bonusModalClosed", false);
    } else {
      setShowModal(false);
    }
  };

  useEffect(() => {
    checkExprTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exprTime, Date.now()]);

  return (
    <>
      {location.code === "zm" ? (
        <Container
          centered={true}
          width={325}
          visible={showModal}
          closable={false}
          footer={false}
          bodyStyle={{
            backgroundColor: "#33BF7F",
            borderRadius: 8,
            height: 450,
            position: "relative",
            padding: 0,
          }}
        >
          <FaTimesCircle
            className="close_icon"
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(false);
              setExprDate();
            }}
          />
          <p className="bonus-time" style={{ margin: "3rem 0 0 2rem" }}>
            Fly Away With
          </p>
          <div className="title-banner" style={{ marginTop: 5 }}>
            <h3 className="title-text">20,000X</h3>
          </div>
          <p className="bonus-time">
            Your bet with{" "}
            <img alt="zambia" src={AviatorIcon} style={{ width: 50 }} />
          </p>{" "}
          <p className="bonus-time">
            Bet {currency?.symbol}
            {min} and stand a chance
          </p>
          <p className="bonus-time">TO WIN</p>
          <h2 className="bonus-amount">
            {currency?.symbol} {price}
          </h2>
          <p className="bonus-desc">Today</p>
          <button
            className="btn"
            onClick={() => {
              //history.push("/bingo/megajp");
              setExprDate();
            }}
          >
            Be a winner!
          </button>
        </Container>
      ) : (
        <Container
          centered={true}
          width={325}
          visible={showModal}
          closable={false}
          footer={false}
          bodyStyle={{
            backgroundColor: "#33BF7F",
            borderRadius: 8,
            height: 450,
            position: "relative",
            padding: 0,
          }}
        >
          <FaTimesCircle
            className="close_icon"
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(false);
              setExprDate();
            }}
          />
          {/* <div className="title-banner">
          <h3 className="title-text">100% Bonus</h3>
        </div>
        <h3 className="title-full-text">
          100% bonus on first deposit of {currency?.symbol}
          {price} and above
        </h3>
        <p className="bonus-desc">
          To qualify, simply make a minimum deposit of{" "}
          <strong>
            {currency?.symbol}
            {price}{" "}
          </strong>
          and you will get another{" "}
          <strong>
            {currency?.symbol}
            {price}{" "}
          </strong>{" "}
          bonus instantly to play. Terms and conditions applies.
        </p>
        <button
          className="btn"
          onClick={() => {
            history.push(Routes.deposit);
            setExprDate();
          }}
        >
          Deposit Now
        </button> */}
          <img className="bonus-image" src={BonusImage} alt="weekly bonus" />
          <section className="bonus-info-section">
            <h2 className="txt-win-no">
              50 Guaranteed <br /> Winners will share
            </h2>
            <h2 className="bonus-amount">
              {currency?.symbol} {price}
            </h2>

            <span className="line" />
            <h3 className="bonus-time">Every Friday</h3>
            <h3 className="bonus-desc">
              Buy a ticket as low as{" "}
              <strong style={{ color: "#2a434b" }}>
                {currency?.symbol}
                {min}
              </strong>
            </h3>
            <button
              className="btn"
              onClick={() => {
                //history.push("/bingo/megajp");
                setExprDate();
              }}
            >
              Be a winner!
            </button>
          </section>

          {/* 
        <img src={GlobeIcon} alt="Globe" className="globe" />
        <h4 style={{ textAlign: "center" }}>
          100% bonus on first deposit of {currency?.symbol}
          {price} and above
        </h4>
        <h3 style={{ textAlign: "center" }}>
          To qualify, simply make a minimum deposit of {currency?.symbol}
          {price} and you will get another {currency?.symbol}
          {price} bonus instantly to play. Terms and conditions applies.
        </h3> */}
          {/* <h4 style={{ textAlign: "center" }}>
          {currency?.symbol}
          {price} MEGA JACKPOT is back this Friday!
        </h4>
        <h3 style={{ textAlign: "center" }}>
          To qualify, you can buy a ticket for as low as {currency?.symbol}
          {min}! There will be 50 guaranteed winners from 6AM - 10PM!
        </h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            primary
            full
            text="Deposit Now"
            onClick={() => {
              history.push(Routes.deposit);
              setExprDate();
            }}
          />
        </div> */}
        </Container>
      )}
    </>
  );
};

export const GamesBonusModal = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const modalClosed = localStorage.getItem("bonusModalClosed");
  const gamesExprTime = localStorage.getItem("gamesExprTime");

  const { location } = useSelector(getLocationSelector);

  let price = "100,000";
  let min = "5000";
  let currency = "";

  if (location.code !== "") {
    currency = location.currency;
  }

  if (location.code === "gh") {
    price = 145;
    min = 10;
  }
  if (location.code === "zm") {
    price = 200;
    min = 10;
  }
  if (location.code === "cd") {
    price = 22000;
    min = "20,000";
  }

  const setExprDate = () => {
    if (!gamesExprTime || new Date(gamesExprTime) <= Date.now()) {
      const currentDateObj = new Date();
      const numberOfMlSeconds = currentDateObj.getTime();
      const addMlSeconds = 86400000;
      const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
      localStorage.setItem("gamesExprTime", newDateObj);
    } else {
      checkExprTime();
    }
  };

  const checkExprTime = () => {
    if (new Date(gamesExprTime) <= Date.now()) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (modalClosed === "true") {
      checkExprTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamesExprTime, Date.now()]);

  return (
    <>
      <Container
        centered={true}
        width={325}
        visible={showModal}
        closable={false}
        footer={false}
        bodyStyle={{
          backgroundColor: "#3066BE",
          borderRadius: 8,
          height: 470,
          position: "relative",
          padding: 0,
        }}
      >
        <FaTimesCircle
          className="close_icon"
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(false);
            setExprDate();
          }}
        />
    
          <img
            className="bonus-image"
            src={FreeBingoImage}
            alt="weekly bonus"
            style={{top:0,position:"unset"}}
          />
          <section className="bingo-info-section">
            <h2 className="txt-win-no">
              40 Guaranteed <br /> Winners will share
            </h2>
            <h2 className="bonus-amount-bingo">
              {currency?.symbol} {price}
            </h2>

            <span className="line" />
            <h3 className="bonus-time">Everyday</h3>

            <button
              className="btn-bingo"
              onClick={() => {
                history.push("/deposit");
                setExprDate();
              }}
            >
              Deposit Now
            </button>
          </section>
  
      </Container>
    </>
  );
};

export const SelectCountryModal = () => {
  const { showModal, loading } = useSelector(getLocationSelector);
  return (
    <>
      {!loading && (
        <Container
          centered={true}
          width={325}
          visible={showModal}
          closable={false}
          footer={false}
        >
          <img src={GlobeIcon} alt="Globe" className="globe" />
          <h4>
            Select your country you <br /> are visiting from
          </h4>
          <div className="card_wrapper">
            {defaultCountries.map((country, index) => {
              return <CountryCard key={index} country={country} />;
            })}
          </div>
        </Container>
      )}
    </>
  );
};

const CountryCard = ({ country }) => {
  const dispatch = useDispatch();
  return (
    <Card
      onClick={() => {
        dispatch(setLocation(country));
        dispatch(handleShowModal());
      }}
    >
      <div className="country-group">
        <img src={country?.flag} alt="" />
        <h5>{country?.name}</h5>
      </div>
      <BettyArrowRight />
    </Card>
  );
};

const Container = styled(Modal)`
  position: relative;
  font-family: "Nunito";
  font-style: normal;
  color:color: #2A434B;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .title-banner {
    background-image: url(${ModalBanner});
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 300px;
    margin-top: 4rem;
  }

  .title-banner2 {
    background-image: url(${ModalBanner2});
    background-repeat: no-repeat;
     background-size: cover;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    height: 65px;
    width: 300px;
  }

  .title-text {
    color: #2a434b;
    font-family:'Inter', sans-serif;
    font-size: 40px;
    font-weight: 900;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
  }

  .title-full-text {
    color: #2a434b;
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    text-align: center;
    margin-left: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  -webkit-text-stroke: 0.5px #fff;
   text-shadow: 0px 0px 16.2981px rgba(0, 0, 0, 0.5);
  }

  .txt-hour {
    color: #2a434b;
    font-family:'Inter', sans-serif;
    font-size:20px;
    margin-top: 3rem;
    margin-bottom: -10px;
    margin-left: 20px;
    text-align: center;
  }

  .txt-desc {
    margin-left: 20px;
    color: #2a434b;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
    font-family:'Nunito';
  }

  .txt-desc-2 {
    margin-left: 20px;
    color: #2a434b;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    margin-bottom:0px;
  }

  .bonus-desc {
    color: #000;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0px;
    margin-top:10px;
    font-weight:600;
  }

  .bonus-amount,.bonus-amount-bingo {
    color: #097F4A;
    background-color: #A4F3CF;
    font-size: 25px;
    font-weight: 800;
    text-align: center;
    margin:20px auto;
    text-transform: uppercase;
    padding:5px;
    border-radius:20px;
    width:80%;
  }

  .bonus-amount-bingo {
    color: #214F9B;
    background-color: #A4C9F3;
  }

  .line {
    display: block;
    border-bottom:1px solid #D7D7D7;
    margin: 0px auto 10px;
    width:100px;
  }

  .bonus-time {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
  }

  .bonus-desc-2 {
    font-size: 14px;
    text-align: center;
    width: 80%;
    margin-left: 40px;
    margin-bottom: 5px;
  }

  .btn,.btn-bingo {
    color: #fff;
    width: 100%;
    height: 40px;
    background: linear-gradient(to right, #FF7612, #F19C46);
    font-weight: 700;
    border-radius: 10px;
    text-transform: uppercase;
    margin-top: 15px;
  }

  .btn-bingo {
    background: #00C66C;
    border:0px;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #2a434b;
    margin-top: 21px;
  }

  .close_icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fff;
    border-radius: 1em;
    background: transparent;
    cursor: pointer;
    font-size: 1.5rem;
    z-index:97000;

    :hover {
      opacity: 0.8;
      transform: scale(1.1);
    }
  }

.bonus-image{
      width: 100%;
    position: absolute;
    top: -15px;
}

.bonus-info-section{
  background:#fff;
  border-radius:16px;
  margin:12px;
  height:280px;
  position:relative;
  padding:20px;
  top:150px;
  z-index:99999;
}

.bingo-info-section{
 background:#fff;
  border-radius:16px;
  margin: 0px 12px;
  height:250px;
  position:relative;
  padding:20px;
  top:0px;
  z-index:99999;
}

.txt-win-no {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

  .globe {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -30px;
    width: 67px;
    height: 67px;
  }

  .btn-continue {
    margin-top: 20px;
    width: 45%;
  }
  .btn-cancel {
    background-color: #d22625;
    width: 45%;
    margin-top: 20px;
  }
  .btn-cancel:hover {
    background-color: #e77776 !important;
  }
  .card_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    margin: 23px 0 32px;
  }
`;

const Card = styled.div`
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #edf9f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  cursor: pointer;

  :hover {
    background: #edf9f8;
  }
  .country-group {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 24px;
      height: 16px;
      border-radius: 2px;
    }

    h5 {
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #6a7b81;
      margin: 0;
      padding: 0;
    }
  }
`;

export const defaultCountries = [
  {
    country_code: "ng",
    code: "ng",
    flag: NigeriaFlag,
    name: "Nigeria",
    currency: {
      symbol: "₦",
      code: "NGN",
    },
  },
  {
    country_code: "gh",
    code: "gh",
    flag: GhanaFlag,
    name: "Ghana",
    currency: {
      symbol: "GH₵",
      code: "GHS",
    },
  },
  {
    country_code: "zm",
    code: "zm",
    flag: ZambiaFlag,
    name: "Zambia",
    currency: {
      symbol: "K",
      code: "K",
    },
  },
  {
    country_code: "drc",
    code: "cd",
    flag: DRCFlag,
    name: "DRC",
    currency: {
      symbol: "FC",
      code: "CDF",
    },
  },
];
